import Swiper from 'swiper'
import { Pagination } from 'swiper/modules'

import 'swiper/css/pagination'

const ProductInfoCarousel = () => ({
  swiper: null,

  init () {
    this.swiper = new Swiper(this.$refs.swiper, {
      autoHeight: true,
      modules: [Pagination],
      pagination: {
        enabled: true,
        el: this.$refs.swiperPagination
      },
      spaceBetween: 16
    })
    console.log('s')
  }
})

export default ProductInfoCarousel
