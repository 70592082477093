import Swiper from 'swiper'
import { Pagination, Navigation } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const FeaturedProductSlider = ({
  mobileLayout
}) => ({
  swiper: null,

  init () {
    if (window.innerWidth < 768) {
      if (mobileLayout === 'slider') {
        this.initSwiper()
      }
    } else {
      this.initSwiper()
    }
  },

  initSwiper () {
    this.Swiper = new Swiper(this.$refs.slider, {
      slidesPerView: 1.2,
      modules: [Navigation, Pagination],
      spaceBetween: 4,
      // pagination
      pagination: {
        el: '.swiper-pagination'
      },
      navigation: {
        enabled: true,
        nextEl: this.$refs.navNext,
        prevEl: this.$refs.navPrev
      },
      breakpoints: {
        768: {
          slidesPerView: 2.3
        },
        1024: {
          slidesPerView: 2.5
        },
        1280: {
          slidesPerView: 3.15
        }
      }
    })

    if (mobileLayout === 'grid' && window.innerWidth < 768) {
      this.Swiper.disable()
    }
  }
})

export default FeaturedProductSlider
